import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from 'src/app/api/helper.service';
import { ApiService } from 'src/app/api/api.service';
import { environment } from 'src/environments/environment';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
// import { KeycloakService } from 'keycloak-angular';
// import * as jwt_decode from "jwt-decode";
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { MatDialog } from '@angular/material';
import { AuthService } from 'src/utils/auth.service';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { colorConfig } from 'src/environments/color-configs';
import { DOCUMENT, Location } from '@angular/common';
import { Title } from '@angular/platform-browser'; 
import { EditComponent } from '../edit/edit.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { BottomSheetComponent } from '../bottom-sheet/bottom-sheet.component';
// import {img} from '../../../../'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();

  username = '';
  showAdminMenu=false;
  envStyles = '';
  role = '';
  showAdminHeaders = true;
  showUser = environment.userPage;
  domain = 'snowpaths';
  bgColor = 'rgb(241, 119, 46)';
  logo ='SnowPaths-logo';
  authResult:any;
  orgId='';
  routerUrl = '';
  appVersion:number;
  orgDetails: any;
  pilotMode: number;
  GIS: number;
  pageLoaded=false;
  isLoginPage = false;
  currentUrl = '';
  isSmartpath: boolean = false;
  isReportPage = false;
  // icon ='../../../../tholos.png'
  constructor(@Inject(DOCUMENT) private _document: HTMLDocument,private apiService: ApiService, private authService: AuthService,
              private dialog: MatDialog, private helper: HelperService, private router: Router,
              private titleService: Title, private bottomSheet: MatBottomSheet, private location: Location) { }

  ngOnInit() {
    const urlOrigin = window.location.origin;
    this.currentUrl = this.location.path();
    this.routerUrl = this.router.url
    if(this.currentUrl.includes("login") || this.currentUrl.includes("forgot-password")){
      this.isLoginPage = true;
    }
    // this.isReportPage = cur
    if(this.isLoginPage){
    let isAvailable = urlOrigin.includes("streetpaths");
    let isFleetpathAvailable = urlOrigin.includes("fleetpaths");
    if(isAvailable){
      this._document.getElementById('appFavicon').setAttribute('href', 'streetpaths-favicon.png');
      this.titleService.setTitle('- streetpaths-4.0'); 
      this.domain="streetpaths";
      this.apiService.addLocalStorage('domain','streetpaths')
    }
    if(isFleetpathAvailable){
      this._document.getElementById('appFavicon').setAttribute('href', 'fleetpaths-favicon.png');
      this.titleService.setTitle('- fleetpaths-4.0'); 
      this.domain="fleetpaths";
      this.apiService.addLocalStorage('domain','fleetpaths')
    }
  }
  if(!this.isLoginPage){
    this.domain=this.apiService.getLocalStorage('domain');
    
  }
    this.handlePortalStyles();
    if(this.authService.getAuthenticatedUser()){
    this.authService.getAuthenticatedUser().getSession((err: any, session: any) => {
      if (session) {
        this.authResult = session;
        this.apiService.setAuthResult(session);
        let tokenValue = jwt_decode<JwtPayload>(session.getIdToken().getJwtToken());
        this.orgId = tokenValue['custom:organizationid'];
        this.getOrgDetails();
      } else {
        if(this.routerUrl !== "/login"){
          this.router.navigate[('/')]
        }else{
          return
        }
      }
    });}
    if(!this.authResult){
      if(this.routerUrl !== "/login"){
        this.router.navigate[('/')]
      }else{
        return
      }
    }
    this.role = this.apiService.getLocalStorage('role');
    if(this.role && this.role.toLowerCase() !== 'admin'){this.showAdminHeaders = false}
    
    this.getUsername();
    this.envStyles = environment.envName;
  }
  handlePortalStyles(){
    if(this.currentUrl.split('/').pop() !== 'Report'){
    this.domain=this.apiService.getLocalStorage('domain');
  } else {
    this.domain = this.apiService.domain;
  }
  this.isSmartpath=JSON.parse(this.apiService.getLocalStorage('isSmartpath'));
  if(this.domain){
    this.updateThemeColor();
    this.logo = colorConfig[this.domain]['logoName'];
    this.bgColor = colorConfig[this.domain]['headerBackground'];
  }
  }
  updateThemeColor() {
    const metaTag = document.querySelector('meta[name="theme-color"]');
    if (metaTag) {
      metaTag.setAttribute('content', colorConfig[this.domain]['headerBackground']);
    } else {
      this.createThemeColorMetaTag();
    }
  }

  createThemeColorMetaTag() {
    const meta = document.createElement('meta');
    meta.setAttribute('name', 'theme-color');
    meta.setAttribute('content', colorConfig[this.domain]['headerBackground']);
    document.head.appendChild(meta);
  }

  toggleSideBar() {
    this.toggleSideBarForMe.emit();
    setTimeout(() => {
      window.dispatchEvent(
        new Event('resize')
      );
    }, 300);
  }
  
  
  async getUsername() {
    const name = this.apiService.getLocalStorage('username');
    if(name && name.includes("steven")){
      this.showAdminMenu=true;
    }
    if (name && name != this.username) {
      this.username = name;
      console.log("name".toLowerCase);
    }
    return name;
  }
  goto(getPath) {
    // this.toggleSideBarForMe.emit();
    this.router.navigate([getPath]);
  }
  isAuthenticated() {
    this.domain = this.apiService.getLocalStorage('domain');
    if(this.domain){
      this.handlePortalStyles();
    }
    const res = this.helper.isAuthenticated();
    return this.helper.isAuthenticated();
    // return true;
  }
  async logoutUser($event) {
    this.authService.logout();
    localStorage.clear();
    this.router.navigate(['/']);
  }
  async onLogout() {
    var userpoolData = JSON.parse(atob(localStorage.getItem('poolData')));
    var user = localStorage.getItem('username');
    var domain = '';
    if(user){
      domain = user.slice(
        user.indexOf('@') + 1,
        user.lastIndexOf('.'),
        ).toLowerCase();
      }
      // this.POOL_DATA.UserPoolId = poolData[domain]['userPoolId'];
    // this.POOL_DATA.ClientId = poolData[domain]['clientId']
    const poolData = {
      UserPoolId: userpoolData[domain]['userPoolId'],
      ClientId: userpoolData[domain]['clientId']
    };
    const userPool = new CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser();
    console.log(cognitoUser);
    this.apiService.domain = undefined;
    try {
      await cognitoUser.signOut();
      this.router.navigate(['login']);
      this.apiService.addLocalStorage('reportData',null);
      localStorage.clear();
      this.helper.openQuickSnackBar("Logout successfully!","Ok")
    } catch (error) {
      console.error('Error during logout:', error);
    }
  }
  changePassword() {
    var title = 'Change Password';

    const modalData = {};
    modalData['editData'] = {};
    const confirmDialog = this.dialog.open(EditComponent, {
      data: {
        title: title,
        message: ' ',
        data: modalData,
        orgId: this.orgId,
        type: 'changePassword'
      },
      minWidth: 60
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.reLogin();
      }
    });
  }
  async reLogin() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Password changed successfully',
        message: 'Please login again with new password for: \n\n ' + this.username
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        // this.onLogout()
        this.updateAndLogoff();
      }
    });
  }
  async getOrgDetails() {
    var url = 'organization?orgId=' + this.orgId;
    this.apiService.getMapDetails(url).subscribe((data: any) => {
      console.log(data);
      if(data){
        this.orgDetails = data;
        this.appVersion = data.organization['Lite']
        this.pilotMode = data.organization['Pilot'];
        this.GIS = data.organization['GIS'];
        this.apiService.setAppVersion(this.appVersion);
      }
      this.pageLoaded = true;
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  updateAndLogoff(){
    var activeUserId = this.apiService.getLocalStorage('activeUserId')
    var url = 'browserDetails?id=' + activeUserId;
    this.apiService.updatedata(url,{}).subscribe((data: any) => {
      console.log(data);
      this.onLogout()
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  openBottomSheet(): void {
    this.bottomSheet.open(BottomSheetComponent);
  }
  gotoInstruction(getType){
    // let data = {};
    // data['infoType']=getType
    this.router.navigate(['/Admin/Instructions'], 
        { queryParams: { instructionType: JSON.stringify(getType) }});
  }
  gotoGridZone(type: string,getUrl: any){
    if(this.pilotMode !==1){
    this.router.navigate([getUrl], 
        { queryParams: { reportScreenType: type }});
    }
  }
}
