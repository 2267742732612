import { ClusterManager } from '@agm/markerclusterer';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';

@Component({
  selector: 'app-all-orgs',
  templateUrl: './all-orgs.component.html',
  styleUrls: ['./all-orgs.component.scss']
})
export class AllOrgsComponent implements OnInit {
  lat = 40.9319951;
  lng = -101.1079758;
  zoom = 5;
  iconPath = {
    url: './assets/logos/location1.png',
    scaledSize: {
      width: 30,
      height: 30
    }
  }
  mapType = 'roadmap'; //roadmap or satellite
  public customStyle = [{  
    "featureType": "poi",  
    "stylers": [{  
        visibility: "off",  
    }]  
  }, ];
  showMap = false;
  OrgDetails: any = [];

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.getOrganizations();
  }
  zoomChange(event: any){
    this.zoom=event;
    console.log(this.zoom)
  }
  async getOrganizations() {
    var url = 'organization';
    await this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        this.OrgDetails = res;
        this.showMap = true;
      }, (error) => {
        console.log(error);
        this.apiService.hideLoader()
      }
    )
  }
  getClusterDetails(cluster: ClusterManager): void {
    // cluster.markers_.map(m => { console.log(m); }); 
    console.log(cluster)
  }
}
